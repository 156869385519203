<template>
  <b-row class="pt-3 mt-3 mb-4">
    <b-col>
      <b-row>
        <b-col cols="12">
          <div class="container-paginate">
            <div
              v-if="totalPages >= 1 || selectedPerPage != 20"
              class="d-flex mr-3 wrap-pages"
            >
              <b-form-select
                class=""
                v-model="selectedPerPage"
                :options="perPageOptions"
                @change="perPage"
              ></b-form-select>
            </div>
            <div>
              <li
                v-if="activePage > 1"
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  prev-next
                  number-control
                  control-page
                "
                @click="toPage(activePage - 1)"
              >
                <img
                  src="@/assets/icons/arrow-page.svg"
                  class="arrow-icon prev-icon"
                />
                Anterior
              </li>
            </div>
            <div class="akkurat-regular" v-if="!isMobile">
              <ul class="pagination">
                <li
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    number-control
                  "
                  :class="{ active: n == activePage, disabled: n < 0 }"
                  v-for="n in pages"
                  :key="n"
                  @click="toPage(n)"
                >
                  <span v-if="n > 0">{{ n }}</span>
                  <span v-else>...</span>
                </li>
              </ul>
            </div>
            <div>
              <li
                v-if="activePage < totalPages"
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  prev-next
                  number-control
                  control-page
                  proximo
                "
                @click="toPage(activePage + 1)"
              >
                Próxima
                <img
                  src="@/assets/icons/arrow-page.svg"
                  class="arrow-icon next-icon"
                />
              </li>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "custom-paginate",
  props: {
    activePage: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    totalPages: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data: () => ({
    selectedPerPage: 10,
    perPageOptions: [
      { text: "10 por página", value: 10 },
      { text: "20 por página", value: 20 },
      { text: "50 por página", value: 50 },
      { text: "100 por página", value: 100 },
    ],
    client: {
      width: 0,
    },
  }),
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    pages: function () {
      let pages = Array.from({ length: this.totalPages }, (v, k) => k + 1);

      if (this.totalPages <= 12) {
        return pages;
      } else if (
        this.activePage <= 6 ||
        this.activePage >= this.totalPages - 5
      ) {
        pages.splice(6, this.totalPages - 12);

        pages.splice(6, 0, -1);

        return pages;
      } else if (this.activePage == 7) {
        pages.splice(7, this.totalPages - 12);
        pages.splice(7, 0, -1);

        return pages;
      } else if (this.activePage == this.totalPages - 6) {
        pages.splice(5, this.totalPages - 12);
        pages.splice(5, 0, -1);

        return pages;
      } else {
        pages.splice(5, this.totalPages - 10);
        pages.splice(5, 0, -1);
        pages.splice(5, 0, this.activePage);
        pages.splice(5, 0, -2);
        return pages;
      }
    },
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    perPage() {
      this.$emit("per-page", this.selectedPerPage);
    },
    toPage(page) {
      if (page < 0) {
        return;
      }
      if (page != this.activePage) {
        this.$emit("to-page", page);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/main.scss";

/* TODO - refac this style, too much repeated code */
// .radius-theme {
//   border-radius: $main-border-radius;
// }
.container-paginate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .container-paginate {
    display: block;
  }

  .control-page {
    justify-content: flex-start !important;
  }
  .control-page.proximo {
    justify-content: flex-end !important;
  }
}
.wrap-pages {
  min-width: 150px;
}

.prev-next {
  border: #275389;
}

.number-control {
  border-radius: 5px;
  padding: 8px 16px;
  transition: 0.3s;
}

.pagination {
  // background: #f8f8f8;
  font-size: 14px;
}
.pagination li + li {
  margin-left: 15px;
}

a {
  color: #d8d8d8;
  text-decoration: none;
}

li:link {
  cursor: pointer;
  background-color: #9b9b9b;
}

li:visited {
  cursor: pointer;
  background-color: #f8f8f8;
  color: #d8d8d8;
}

li:hover,
li.active {
  cursor: pointer;
  background-color: #f6f6f8;
  font-weight: 600;
}

li.disabled {
  &:hover {
    cursor: default;
    background-color: white;
    color: #9b9b9b;
  }
}

li:active {
  cursor: pointer;
  background-color: #f6f6f8;
  font-weight: 600;
}
.arrow-icon {
  // filter: brightness(0) invert(0);
  margin-top: -2px;
}
.prev-icon {
  transform: rotate(180deg);
}

// .prev-icon:hover,
// .next-icon:hover {
//   background: none;
//   font-weight: 600;
// }
.control-page {
  font-size: 14px;
}
.control-page .prev-icon {
  margin-right: 15px;
}
.control-page .next-icon {
  margin-left: 15px;
}
</style>